import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Maritime() {
  return (
    <Layout pageTitle="Maritime Festival">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Maritime Festival - USI Projects</title>
        <meta name="description" content="The Belfast Maritime Festival has been a successful city-wide event for 14 years, attracting up to 80,000 people to the waterfront over the 3 day event."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

      <section class="text-gray-700 body-font heading-container-maritime">

      <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Maritime Festival</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-yellow-300">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl  ">
                  The Belfast Maritime Festival has been a successful city-wide event for 14 years, attracting up 
                  to 80,000 people to the waterfront over the 3 day event. However, there is the opportunity to take 
                  the festival to the next level - appeal internationally, grow particpation, commercial investment and 
                  vistor satisfaction. Working with partners Flint Culture and Group Nao, we developed the 5 year strategy 
                  for the Belfast Maritime Festival - focusing on how the festival can appeal to new audiences and grow 
                  internationally. This included key stakeholder consultation, engagement with over 45 different cultural, 
                  creative, business and special intterest groups and designing strategic ambitions and programming themes to 
                  take the festival to the next level. 
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase ">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed   mt-0">Events & Festivals<br/>
                  Tourism<br/>Regeneration
                </p>
                <p class="text-2xl font-bold uppercase   mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed   mt-0">Stakeholder Engagement<br/>
                  Strategy development<br/>Concept Designs</p>
              </div>
            </div>
          </section>
        </div>
      </section>


      <div class="py-4 md:my-16 md:py-16 w-6/6 items-center">
        <img class="items-center w-full" src="../img/projects/flowto2025-maritime.webp" alt="Flow to 2025"/>
      </div>

      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="py-4 w-full md:pl-20 md:pr-20"
      src="../img/projects/lagan-aerial.webp" alt="Lagan Aerial"/>


      <div class="md:mt-16 md:pt-16 ">
        <img class="md:pr-16 md:pl-16 w-full" src="../img/projects/maritime-3-billboard-headers.webp" alt="Maritime Festival"/>
        <img class="md:pr-16 md:pl-16 w-full" src="../img/projects/maritime-billboard.webp" alt="Maritime Festival"/>
      </div>

      <div class="py-4 md:mt-16 md:pt-16 md:pr-16 md:pl-16">
        <img class="w-full" src="../img/projects/3-flags-maritime.webp" alt="Maritime Festival"/>
      </div>
      
        <img data-scroll data-scroll-offset="100" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20 md:pt-20 md:pb-20"
        src="../img/projects/connectedbywater.webp" alt="Connected by Water"></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-yellow-300">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../driverless" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
